@import 'portfolioPage';
@import 'colors';

.bioPageContent {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }

  .bioPagePhoto {
    height: auto;
    transition: 0.3s;
    margin: 10px;
    max-width: 100%;
    border-radius: 20px;

    @media only screen and (min-width: 768px) {
      width: 30%;
    }
  }

  .bioPageTextCard {
    padding: 16px;
    margin: 10px;
    font-size: large;
    background-color: $opal;
    border-radius: 20px;
  }
}

.bioPageContent:nth-of-type(2n) {
  @media only screen and (min-width: 768px) {
    flex-direction: row-reverse;
  }
}