/* SCSS RGB */
$prussian-blue: rgba(18, 38, 58, 1);
$maximum-blue-green: rgba(6, 188, 193, 1);
$opal: rgba(197, 216, 209, 1);
$isabelline: rgba(244, 237, 234, 1);
$light-orange: rgba(244, 209, 174, 1);

/* SCSS Gradient */
$gradient-top: linear-gradient(0deg, #12263aff, #06bcc1ff, #c5d8d1ff, #f4edeaff, #f4d1aeff);
$gradient-right: linear-gradient(90deg, #12263aff, #06bcc1ff, #c5d8d1ff, #f4edeaff, #f4d1aeff);
$gradient-bottom: linear-gradient(180deg, #12263aff, #06bcc1ff, #c5d8d1ff, #f4edeaff, #f4d1aeff);
$gradient-left: linear-gradient(270deg, #12263aff, #06bcc1ff, #c5d8d1ff, #f4edeaff, #f4d1aeff);
$gradient-top-right: linear-gradient(45deg, #12263aff, #06bcc1ff, #c5d8d1ff, #f4edeaff, #f4d1aeff);
$gradient-bottom-right: linear-gradient(135deg, #12263aff, #06bcc1ff, #c5d8d1ff, #f4edeaff, #f4d1aeff);
$gradient-top-left: linear-gradient(225deg, #12263aff, #06bcc1ff, #c5d8d1ff, #f4edeaff, #f4d1aeff);
$gradient-bottom-left: linear-gradient(315deg, #12263aff, #06bcc1ff, #c5d8d1ff, #f4edeaff, #f4d1aeff);
$gradient-radial: radial-gradient(#12263aff, #06bcc1ff, #c5d8d1ff, #f4edeaff, #f4d1aeff);