@import 'colors';

.navBar {
  margin: 0;
  padding: 0;
  overflow: visible;
  top: 0;
  background-color: $opal;
  display: flex;
  justify-content: space-between;

  .leftLabel {
    display: block;
    padding: 14px 16px;
    align-content: center;
    font-size: x-large;
    color: $prussian-blue;
  }

  .rightLabel {
    display: block;
    padding: 14px 16px;
    align-content: center;
    font-size: x-large;
    color: $prussian-blue;
  }

  .navLinks {
    display: flex;
    justify-content: space-evenly;
    float: left;
    font-size: x-large;
  }

  .navLink a:hover {
    background-color: $maximum-blue-green;
  }

  .navLink a {
    display: flex;
    color: $prussian-blue;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }

  .activeLink {
    background-color: $maximum-blue-green;
  }

  .hamburgerMenu {

  }
}