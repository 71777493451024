.portFolioPage {
  padding: 13px;
  max-width: 1000px;

  .portFolioPageHeader {
    text-align: center;
  }
}

.portFolioPageContainer {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}