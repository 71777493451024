@import 'colors';

.cvItems {
  display: flex;
  flex-direction: column;
}

.cvItem {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  @media only screen and (min-width: 768px) {
    margin: 0
  }

  .cvItemContent {
    width: 100%;
    @media only screen and (min-width: 768px) {
      width: 70%;
    }
  }

  .cvItemDate {
    color: $isabelline
  }
}

.cvItemContent {
  background-color: $opal;
  padding: 20px;
  margin: 10px;
  border-radius: 20px;

  @media only screen and (min-width: 768px) {
    margin: 20px;
  }
}

@mixin cvItemContainer {
  width: 100%;
  @media only screen and (min-width: 768px) {
    width: 90%;
  }

  .cvItem {
    @media only screen and (min-width: 768px) {
      width: 100%;
    }
  }
}

.cvItemLeftContainer {
  @include cvItemContainer();
  align-self: start;

  .cvItem {
    @media only screen and (min-width: 768px) {
      width: 100%;
      flex-direction: row-reverse;
    }
  }
}

.cvItemRightContainer {
  @include cvItemContainer();
  align-self: end;

  .cvItem {
    @media only screen and (min-width: 768px) {
      width: 100%;
      flex-direction: row;
    }
  }
}