@import 'colors';
@import 'cv';
@import "navBar";
@import 'portfolioPage';
@import 'bioPage';
@import 'projects';

body {
  background-color: $prussian-blue;
}
